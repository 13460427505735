import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import StoryblokClient, { Richtext } from 'storyblok-js-client';
// eslint-disable-next-line import/no-cycle
import { StoryblokService } from '../../services';
import { maskAssetUrl } from '../props/asset';
import { multiLink } from '../props/multi-link';
import { AnyProps } from '../types';
import { TextLinkComponentProps } from './roche-text-link';

interface HeadlineComponentProps {
  headline: string;
  level: number;
}

interface OverlineComponentProps {
  overline_text: string;
}
interface IconComponentProps {
  icon?: string;
  height?: string;
}

interface CookieBannerLinkComponentProps {
  text?: string;
}

interface RichtextComponentConfiguration {
  footnote: boolean;
}

const sanitizeText = (text = '') => text.replace(/"/g, '&quot;');

const componentPropRenderer = {
  'roche-text-link': (blok: TextLinkComponentProps): string | null => {
    if (typeof blok.link !== 'object') {
      return null;
    }

    const { href } = multiLink('href', blok.link);

    /* Dev-note: Since this component is also used to link to assets, masking the url is required,
     * otherwise crawlers pick up the original link to Storyblok assets manager
     */
    return `href="${maskAssetUrl(href)}" text="${sanitizeText(
      blok.text,
    )}" target="${blok.target}" left-icon="${blok.left_icon}" right-icon="${
      blok.right_icon
    }"`;
  },

  'roche-headline': (blok: HeadlineComponentProps): string => `level="${blok.level}" headline="${sanitizeText(blok.headline)}"`,

  'roche-overline': (blok: OverlineComponentProps): string => `overline-text="${sanitizeText(blok.overline_text)}"`,

  'roche-tooltip': (blok): string => `tooltip-trigger="${blok.tooltip_trigger}" tooltip-text="${blok.tooltip_text}" link-text="${blok.link_text}" link-url="${blok.link_url}" link-target="${blok.link_target}"`,

  'roche-icon': (blok: IconComponentProps): string => `height="${blok.height}" icon="${blok.icon}"`,

  'roche-cookie-banner-link': (blok: CookieBannerLinkComponentProps) => `text="${sanitizeText(
    blok.text,
  )}" onclick="window.OneTrust?.ToggleInfoDisplay()"`,
};

const storyblokClient = new StoryblokClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  accessToken: StoryblokService.getConfig().options.accessToken as string,
});

storyblokClient.setComponentResolver(
  (component, blok): string => `<${component} ${componentPropRenderer[component](blok)}></${component}>`,
);

/**
 * Storyblok's richtext editor always forces blok level components to close the previous "tag".
 *
 * This means we need to combine the <p> tags surrounding the roche-text-link into the same one,
 * with roche-text-link as their child
 *
 * In order to maximize reusability of Storyblok's richTextResolver,
 * we use pattern matching and work directly on the "stringified markup" output by their tools.
 *
 * NOTE: The editor can still make links behave like their own Blok,
 * by inserting 2 carriage returns, instead of 1, after a paragraph.
 */
const forceLinksInsideParagraphs = (
  markupString: string,
  { footnote }: RichtextComponentConfiguration,
): string => markupString
  .replace(
    /<roche-text-link/gm,
    `<roche-text-link ${footnote ? 'size="footnote"' : ''}`,
  )
  .replace(/<\/p><roche-tooltip/gm, ' <roche-tooltip')
  .replace(/<\/roche-tooltip><p>/gm, '</roche-tooltip> ')
  .replace(/<\/p><roche-text-link/gm, ' <roche-text-link')
  .replace(/<\/roche-text-link><p>/gm, '</roche-text-link> ')
  .replace(/<p><\/p>/, '');

/**
 * External Links are written by the Storyblok helper as `href="/http://…"`
 * However, they should not have the initial slash. This function removes it.
 */
const fixExternalLinks = (markupString: string): string => markupString
  .replace(/href="\/https:\/\//gm, 'href="https://')
  .replace(/href="\/http:\/\//gm, 'href="http://');

const markupFromRichtextField = (
  storyblokHtmlSchema: Richtext,
  configuration: RichtextComponentConfiguration,
): string => fixExternalLinks(
  forceLinksInsideParagraphs(
    storyblokClient.richTextResolver.render(storyblokHtmlSchema),
    configuration,
  ),
);

export const RocheRichtext = ({ blok, slot }: AnyProps): JSX.Element => React.createElement('roche-richtext', {
  // eslint-disable-next-line no-underscore-dangle
  uid: blok._uid,
  slot,
  'capitalize-first-Letter': blok.capitalize_first_letter || undefined,
  footnote: blok.footnote || undefined,
  type: blok.type || undefined,
  dangerouslySetInnerHTML: {
    __html: markupFromRichtextField(blok.text, { footnote: blok.footnote }),
  },
});
