import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { blokToComponent, getComponent } from '../components';
import { Navigation } from '../components/navigation';

export default function Component() {
  const data = useStaticQuery(graphql`
    query {
      storyblokEntry(id: { eq: "storyblokentry-406625530-default" }) {
        id
        name
        content
      }
    }
  `);

  const blok = JSON.parse(data.storyblokEntry.content);
  const storyId = data.storyblokEntry.id;

  return (
    <div>
      <Navigation currentPage={'mainpage'}/>
      <roche-grid layout='12'>
      {blokToComponent({
        blok,
        getComponent,
        storyId,
      })}
      </roche-grid>
    </div>
  );
}
