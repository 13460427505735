import React from 'react';
import { StringService } from '../../services';
import { GlobalConfigProps } from '../../services/dom/types';

export const RocheGlobalConfig = (props: GlobalConfigProps): JSX.Element => {
  const newProps = Object.keys(props).reduce((accumulator, prop) => {
    const value = props[prop];
    const parsedValue = Array.isArray(value) || typeof value === 'object'
      ? JSON.stringify(value)
      : value;

    return {
      ...accumulator,
      [`data-${StringService.camelToKebab(prop)}`]: parsedValue,
    };
  }, {});

  return <roche-global-config {...newProps}></roche-global-config>;
};
