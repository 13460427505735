import { Link } from './types';

const mockLink = {
  url: '',
  cached_url: '',
  linktype: '',
  story: {
    url: '',
  },
};

export const NavigationService = {
  rootAlias: 'home',

  // primary and secondary nav
  currentPageCssClass: 'current-page',

  getParsedMultiLink(link: Link): string {
    if (NavigationService.isExternalUrl(link)) {
      return NavigationService.getParsedLink(link);
    }

    const url = NavigationService.getParsedLink(link).replace(
      NavigationService.rootAlias,
      '',
    );
    const anchor = link.anchor ? `#${link.anchor}` : '';

    return `/${url}${anchor}`.replace('//', '/');
  },

  isExternalUrl: (link: Link): boolean => link.url !== '',

  getParsedLink: (link: Link = mockLink): string => link.url || link.story?.url || '',
};
