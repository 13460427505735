import React from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { blokToComponent } from '../helpers';
import { Props } from '../types';

interface GridProperties {
  layout: string;
  'full-width': boolean,
  color: string,
  slot: string,
  'hide-in-print-view'?: boolean,
}

const tagName = 'roche-grid';

const storyblokLayoutMap = {
  'roche-grid-1-4-1-6': '1-4-1-6',
  'roche-grid-12': '12',
  'roche-grid-3-1-5-3': '3-1-5-3',
  'roche-grid-3-3-3-3': '3-3-3-3',
  'roche-grid-4-4-4': '4-4-4',
  'roche-grid-4-8': '4-8',
  'roche-grid-5-1-6': '5-1-6',
  'roche-grid-5-7': '5-7',
  'roche-grid-6-1-4-1': '6-1-4-1',
  'roche-grid-6-6': '6-6',
  'roche-grid-7-5': '7-5',
  'roche-grid-8-4': '8-4',
  'roche-grid-8': '8',
  'roche-grid-6': '6',
  'roche-grid-6-1-5': '6-1-5',
  'roche-grid-8-1-3': '8-1-3',
  'roche-grid-7-1-4': '7-1-4',
};

const storyblokGridAreasMap = new Map([
  ['slotted_left', 'left'],
  ['slotted_center_left', 'center-left'],
  ['slotted_center', 'center'],
  ['slotted_center_right', 'center-right'],
  ['slotted_right', 'right'],
  ['slotted', ''],
]);

const getLayoutType = (id: string): string => {
  if (!storyblokLayoutMap[id]) {
    throw new Error(
      `${tagName}: could not find a layout match for ${id} component.`,
    );
  }
  return storyblokLayoutMap[id];
};

const Grid = ({
  blok, getComponent, storyId, slot: gridComponentSlot,
}: Props): JSX.Element => {
  const gridChildren = [];

  let gridProps: GridProperties = {
    layout: getLayoutType(blok.component),
    'full-width': blok.full_width,
    color: blok.color,
    slot: blok.slot || gridComponentSlot,
  };

  if (blok.hide_in_print_view) {
    gridProps = { ...gridProps, 'hide-in-print-view': true };
  }

  storyblokGridAreasMap.forEach((slot: string, slotKey: string): void => {
    if (blok[slotKey]) {
      gridChildren.push(
        blok[slotKey].map(
          (component: StoryblokComponent<string>) => blokToComponent({
            blok: component,
            getComponent,
            slot,
            storyId,
          }, 'grid'),
        ),
      );
    }
  });

  return React.createElement(
    tagName,
    gridProps,
    gridChildren,
  );
};

export const gridComponents = Object
  .keys(storyblokLayoutMap)
  .reduce((accumulator, componentName) => ({
    ...accumulator,
    [componentName]: Grid,
  }), {});
