import { blokToComponent } from '../helpers';
import { AnyProps } from '../types';

export const QuestionnaireReference = ({
  blok,
  getComponent,
  editable = false,
}: AnyProps): JSX.Element => {
  const reference = blok.reference.partialReference?.content?.partial;

  return reference?.map((content) => blokToComponent({
    blok: content,
    getComponent,
    storyId: content.id,
    editable,
  }));
};
