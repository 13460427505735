import React from 'react';
import { AnyProps } from '../types';

export const PartnerAssignment = ({ blok }: AnyProps): JSX.Element => (
  blok.content.map((module) => {
    const attributes = {
      'data-module': module.reference.compositionPath,
      'data-dom-selector': module.reference.domElementSelector,
      'data-partner-target': module.reference.partnerTargetPage,
    };

    return (
      <div className='module-assignment' {...attributes}></div>
    );
  })
);
