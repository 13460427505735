import React from 'react';
import { Props } from '../types';

export const RocheLeadText = ({
  blok,
  slot,
}: Props): JSX.Element => React.createElement(
  'roche-lead-text',
  {
    slot,
    dangerouslySetInnerHTML: { __html: blok.slotted_content },
  },
);
