import React, { Fragment } from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { GetComponentType } from '../types';
import { blokToComponent } from './blok-to-component';
import { FilteredProps } from './filter-props';

export const slottedToComponents = (getComponent: GetComponentType, slotted: FilteredProps['slotted'], storyId?: number | string): JSX.Element[] => Object.keys(slotted).map((childSlotName, index) => {
  const components = ((
    slotted[childSlotName] instanceof Array ? slotted[childSlotName] : [slotted[childSlotName]]
  ) as StoryblokComponent<string>[]);

  if (childSlotName === 'unnamed' || !components.length) {
    return (
      <Fragment key={index}>
        {components.map((component) => blokToComponent({ getComponent, blok: component, storyId }, 'slotted-unnamed'))}
      </Fragment>
    );
  }
  return components.length === 1
    ? (blokToComponent({
      getComponent, blok: components[0], slot: childSlotName, storyId,
    }, 'slotted'))
    : (
      <Fragment>
        {components.map((component) => blokToComponent({
          getComponent, blok: component, slot: childSlotName, storyId,
        }, `slotted-${childSlotName}`))}
      </Fragment>
    );
});
