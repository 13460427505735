import React from 'react';
import { StoryblokComponentType } from 'gatsby-source-storyblok';
import { Props } from '../types';

export const blokToComponent = (
  props: Props<StoryblokComponentType<string>>,
  keyPrefix?: string,
): JSX.Element => {
  const CustomChild = props.getComponent(props.blok?.component);

  // eslint-disable-next-line no-underscore-dangle
  const key = typeof keyPrefix === 'string' ? `${keyPrefix}-${props.blok._uid}` : undefined;
  const newProps = {
    ...props,
    ...(key ? { key } : {}),
  };

  // eslint-disable-next-line no-underscore-dangle
  return <CustomChild {...newProps} key={props.blok._uid} />;
};
