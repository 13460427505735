const characters = 'abcdefghijklmnopqrstuvwxyz0123456789-_'; // base 64

export const StringService = {
  generate(length = 32): string {
    return [...new Array(length)]
      .map(
        () => characters[
          Math.floor(Math.random() * characters.length) % characters.length
        ],
      )
      .join('');
  },

  sanitizeUrl(string: string): string {
    return string.replace(/^http:\/\//, '').replace(/\/+/g, '/');
  },

  camelToKebab(string: string): string {
    if (string) {
      return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
    return 'div';
  },

  snakeToKebab(string: string): string {
    return string.replace(/_([a-z])/g, '-$1');
  },

  kebabToCamel(string: string): string {
    return string.replace(/-([a-z])/g, (_, s) => s.toUpperCase());
  },

  isImageUrl(url: string): boolean {
    return (
      [
        'webp',
        'avif',
        'png',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'bmp',
        'tif',
        'tiff',
      ].includes(this.getExtension(url))
    );
  },

  stringToJavascript<T>(input: string, fallback: T): T {
    try {
      const parsed = JSON.parse(input);
      return parsed;
    } catch {
      // eslint-disable-next-line no-console
      console.warn(
        `Failed to parse ${input}. Fallback value will be used instead`,
      );
      return fallback;
    }
  },

  getExtension: (filename: string): string => filename.split('.').pop().toLowerCase(),

  removeFileExtension: (filename: string): string => filename.replace(/\.[^/.]+$/, ''),

  unslugify: (slug: string) => {
    const result = slug.replace(/-/g, ' ');
    return result.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  },
};
