import React from 'react';
import { filterProps } from '../helpers';
import { Props } from '../types';
import { Link } from '../../services/navigation/types';
import { maskAssetUrl } from '../props/asset';

export interface TextLinkComponentProps {
  text?: string;
  target?: string;
  left_icon?: string;
  right_icon?: string;
  link: Link;
}

/*
 * TODO: On a new project, rename the "link" field to "href".
 * This way, we won't even need a custom component.
 */
export const RocheTextLink = ({ blok, slot }: Props): JSX.Element => {
  const { props } = filterProps(blok);

  /* Dev-note: Since this component is also used to link to assets, masking the url is required,
   * otherwise crawlers pick up the original link to Storyblok assets manager
   */
  return (
    <roche-text-link
      {...props}
      href={maskAssetUrl(props.link)}
      slot={slot}
    />
  );
};
