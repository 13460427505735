import { gridComponents } from './grid';
import { responsiveTable } from './responsive-table';
import { RocheGlobalConfig } from './global-config';
import { RocheIcon } from './roche-icon';
import { RocheLeadText } from './roche-lead-text';
import { RocheRichtext } from './richtext';
import { RocheTextLink } from './roche-text-link';
import { PartnerAssignment } from './partner-assignment';
import { CompositionReference } from './composition-reference';
import { Composition } from './composition';
import { Partial } from './partial';
import { PartialReference } from './partial-reference';
import { CareInstructionReference } from './care-instruction-reference';
import { QuestionnaireReference } from './questionnaire-reference';
import { QuestionReference } from './question-reference';
import { Question } from './question';

// eslint-disable-next-line import/no-default-export
export default {
  ...gridComponents,
  ...responsiveTable,
  RocheGlobalConfig,
  RocheIcon,
  RocheLeadText,
  RocheRichtext,
  RocheTextLink,
  PartnerAssignment,
  CompositionReference,
  Composition,
  Partial,
  PartialReference,
  CareInstructionReference,
  QuestionnaireReference,
  QuestionReference,
  Question,
};
