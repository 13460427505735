import * as React from 'react';

interface Props {
  currentPage: 'mainpage' | 'docs' | 'packages';
}

export const Navigation = ({ currentPage }: Props) => (

  <roche-header>
    <roche-navigation slot="secondary">
      <ul>
        <li className={currentPage === 'mainpage' ? 'current-page' : undefined}>
          <a href="/">About MAAS</a>
        </li>
        <li className={currentPage === 'docs' ? 'current-page' : undefined}>
          <a href="/docs">Docs</a>
        </li>
        <li>
          <a href="https://bundle-center.dpmsys.roche.com/partner" target="_blank">Packages</a>
        </li>
      </ul>
    </roche-navigation>
  </roche-header>
);
