import { blokToComponent } from '../helpers';
import { AnyProps } from '../types';

export const CompositionReference = ({
  blok,
  getComponent,
  editable = false,
}: AnyProps): JSX.Element => {
  const composition = blok.reference.content;
  return blokToComponent({
    blok: composition,
    getComponent,
    storyId: composition?.id,
    editable,
  });
};
