import { blokToComponent } from '../helpers';
import { AnyProps } from '../types';

export const Question = ({
  blok,
  getComponent,
  storyId,
  editable = false,
}: AnyProps): JSX.Element => {
  blok.question_component.forEach((component) => {
    component.reference_id = blok.reference_id; // eslint-disable-line no-param-reassign
  });

  return blok.question_component?.map((component) => blokToComponent({
    blok: component,
    getComponent,
    storyId,
    editable,
  }));
};
