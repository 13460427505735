import { BrowserDetectionService, StringService } from '../../services';

export interface AssetData {
  id: string;
  filename: string;
  title: string;
  copyright: string;
  focus: string;
  alt: string;
  name: string;
  fieldtype?: string;
}

export const maskAssetUrl = (url: string): string => url.replace('a.storyblok.com', process.env.GATSBY_ROCHE_ASSET_URL_MASK);

// TODO: Change to automatic WebP support from Storyblok image service.
const getEncodeFormat = (): string => (!BrowserDetectionService.isWebPSupported() ? '' : 'filters:format(webp):quality(90)/');
const convertToStoryblokImageService = (url: string): string => `${maskAssetUrl(url)}/m/$0x$1/${getEncodeFormat()}`;

export const parseAssetSource = (filename: string): string => {
  if (typeof filename !== 'string') {
    return filename;
  }

  return StringService.isImageUrl(filename)
    ? convertToStoryblokImageService(filename)
    : maskAssetUrl(filename);
};

export const parseDownloadSource = (filename: string): string => {
  if (typeof filename !== 'string') {
    return filename;
  }

  return maskAssetUrl(filename);
};

export const asset = (key: string, data: AssetData): Record<string, string> => ({
  [key]: JSON.stringify({
    src: parseAssetSource(data.filename),
    caption: data.title,
    copyright: data.copyright,
    alt: data.alt,
  }),
});
