import {IPluginRefObject} from 'gatsby';
import {StoryblokBridgeConfigV2, StoryblokBridgeV2} from 'gatsby-source-storyblok';
import {ISbResult} from 'storyblok-js-client/types/interfaces';
import {storyblokConfig} from '../../../configuration/storyblok-config';
import {BrowserDetectionService} from '../browser-detection';
import StoryblokClient from "storyblok-js-client";

export interface StoryblokDatasourceResult extends ISbResult {
    slug: string;
    params: Record<string, unknown>;
    page: number;
}

export interface APIConnectorPluginSettings extends IPluginRefObject {
    resolve: string;
    options?: {
        accessToken: string;
        typeName?: string;
        fieldName?: string;
        url?: string;
        headers?: {
            Token: string;
            Version: string;
        };
        resolveRelations?: string[];
    };
}

const restClient = new StoryblokClient({
    accessToken: `${process.env.GATSBY_STORYBLOK_SPACE_API_KEY}`,
    cache: {
        clear: "auto",
        type: "memory",
    },
});


let structureCache = null;
let docsCache = null;

interface DocStructure {
    title? : string;
    slug? : string;
    contents? : any;
}
export const StoryblokService = {
    async getDocumentationAsTree() {
        if (structureCache) {
            return structureCache;
        }
        let contents = {};
        let structure: DocStructure = {};
        const createStructure = (items) => {
            structure = items.reduce((r, p) => {
                let split = p.full_slug.split('/');
                split.shift();
                split.shift();
                let href = split.join('-');
                let path = p.full_slug.split('/'),
                    file = path.pop(),
                    final = path.reduce((o, slug) => {
                        let temp = (o.children = o.children || []).find(q => q.slug === slug);
                        if (!temp) {
                            o.children.push(temp = {
                                title: slug,
                                slug: slug,
                                href: href,
                                data: null
                            });
                        }
                        temp.data = p.slug === slug ? p : null;
                        return temp;
                    }, r);
                (final.children = final.children || []).push({
                    title: p.name,
                    slug: file,
                    href: href,
                    data: p
                });
                contents[href] = typeof p.content.content === 'string' ? p.content.content : '';
                return r;
            }, {});

        }
        const docs = await this.getDocumentationPagesList();
        createStructure(docs);
        structure.title = 'Documentation';
        structure.slug = 'documentation';
        structure.contents = contents;
        return structure;
    },
    async getDocumentationPagesList() {
        if (docsCache) {
            return docsCache;
        }
        docsCache = await restClient.getAll('cdn/stories', {
            version: "draft",
            starts_with: 'partner-documentation/documentation/'
        });
        return docsCache;
    },
    getConfig(): APIConnectorPluginSettings {
        return (storyblokConfig || {}) as APIConnectorPluginSettings;
    },

    getObject(options: StoryblokBridgeConfigV2): StoryblokBridgeV2 | undefined {
        if (BrowserDetectionService.isBrowserContext()) {
            return new window.StoryblokBridge(options);
        }
        return undefined;
    },

    async getDatasources(
        client: StoryblokClient,
        slugs: string[],
        params?: Record<string, string | number>,
    ): Promise<ISbResult[]> {
        const initialResponses = await Promise.all(
            slugs.map((slug) => this.createDatasourceRequest(client, slug, 1, params)),
        );

        const additionalRequests: Promise<StoryblokDatasourceResult>[] = [];
        initialResponses
            .forEach((datasource) => {
                if (datasource.total <= datasource.perPage) {
                    return;
                }
                const totalPages = datasource.total / datasource.perPage;

                for (let page = 1; page < totalPages; page += 1) {
                    additionalRequests
                        .push(this.createDatasourceRequest(client, datasource.slug, page + 1, params));
                }
            });

        if (additionalRequests.length === 0) {
            return initialResponses;
        }

        const additionalResponses = await Promise.all(additionalRequests);

        return this.combineDatasourceResponses(initialResponses, additionalResponses);
    },

    createDatasourceRequest(
        client: StoryblokClient,
        slug: string,
        page: number,
        params?: Record<string, string | number>,
    ): Promise<StoryblokDatasourceResult> {
        return client.get('cdn/datasource_entries', {
            datasource: slug,
            per_page: 100,
            page,
            ...params,
        }).then((response) => {
            const datasourceResponseObject = {
                ...response,
                slug,
                params,
                page,
            };
            return datasourceResponseObject as StoryblokDatasourceResult;
        });
    },

    /**
     * TODO:
     * refactor this method to use a single argument combineDatasourceResponses(datasourcesResponses)
     * Discussion doc
     * [{1, data}, {2, data}, {1, data}, {1, data},] ->
     *  {}
     *  {
     *   1: [data]
     *   2: [data]
     *  }
     * return [1, [data], [2, [data]]]
     *
     */
    combineDatasourceResponses(
        allDatasources: StoryblokDatasourceResult[],
        newDatasources: StoryblokDatasourceResult[],
    ): StoryblokDatasourceResult[] {
        newDatasources.forEach((datasource) => {
            const firstDatasource = allDatasources.find(
                (searchDatasource) => searchDatasource.slug === datasource.slug
                    && searchDatasource.params === datasource.params,
            );
            if (firstDatasource) {
                firstDatasource.data.datasource_entries = firstDatasource.data?.datasource_entries.concat(
                    datasource.data?.datasource_entries,
                );
                firstDatasource.perPage += datasource.perPage;
            }
        });
        return allDatasources;
    },

    isPreviewContent: (): boolean => StoryblokService.getConfig().options?.headers?.Version === 'draft',

    isInEditor: (): boolean => !!StoryblokService.getObject(),
};
