import { blokToComponent } from '../helpers';
import { AnyProps } from '../types';

export const Partial = ({
  blok,
  getComponent,
  storyId,
  editable = false,
}: AnyProps): JSX.Element => blok.partial?.map((component) => blokToComponent({
  blok: component,
  getComponent,
  storyId,
  editable,
}));
