import React from 'react';
import { StoryblokComponentType, storyblokEditable } from '@storyblok/react';

import { StringService } from '../services';
import { filterProps, slottedToComponents } from './helpers';
import { Props } from './types';

export const Default = ({
  blok, getComponent, slot, storyId, editable,
}: Props<StoryblokComponentType<string>>): JSX.Element => {
  const { props, slotted } = filterProps(blok);

  const editableProps = editable ? storyblokEditable(blok) : {};

  const allProps = {
    ...props,
    ...(slot ? { slot } : {}),
    // eslint-disable-next-line no-underscore-dangle
    ...(blok._uid ? { uid: blok._uid } : {}),
    // eslint-disable-next-line no-underscore-dangle
    ...((blok.id || blok._uid) ? { id: blok.id || blok._uid } : {}),
    storyId,
    ...editableProps,
  };

  const CustomComponent = StringService.camelToKebab(blok.component);
  const children = slottedToComponents(getComponent, slotted, storyId);
  return (
    <CustomComponent {...allProps}>{children}</CustomComponent>
  );
};
