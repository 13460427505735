import React from 'react';
import { blokToComponent } from '../helpers';
import { AnyProps } from '../types';

export const Composition = ({
  blok,
  getComponent,
  editable = false,
}: AnyProps): JSX.Element => <>
    {blok.body?.map((composition) => blokToComponent({
      blok: composition,
      getComponent,
      storyId: composition.id,
      editable,
    }))}

    {(blok.custom_style)
      && <style type="text/css">{blok.custom_style}</style>}

    {(blok.stylesheet)
      && <link rel="stylesheet" type="text/css" href={blok.stylesheet} />}
  </>;
