import React from 'react';
import { AnyProps } from '../types';

interface IconProps {
  color: string;
  height: string;
  icon: string;
}

export const RocheIcon = ({ blok }: AnyProps): JSX.Element => {
  const { height, icon, color } = blok as IconProps;

  const fillStyle = !color
    ? {}
    : { fill: color };

  return (
    <roche-icon
      height={height}
      icon={icon}
      style={{
        display: 'inline-flex',
        ...fillStyle,
      }}
    />
  );
};
